export const faceEmojiMap = {
  '[ms:微笑]': 'ms_001',
  '[ms:撇嘴]': 'ms_002',
  '[ms:色]': 'ms_003',
  '[ms:捂鼻]': 'ms_004',
  '[ms:得意]': 'ms_005',
  '[ms:流泪]': 'ms_006',
  '[ms:害羞]': 'ms_007',
  '[ms:闭嘴]': 'ms_008',
  '[ms:睡]': 'ms_009',
  '[ms:强忍泪水]': 'ms_010',
  '[ms:流汗害怕]': 'ms_011',
  '[ms:发怒]': 'ms_012',
  '[ms:抛媚眼]': 'ms_013',
  '[ms:呲牙]': 'ms_014',
  '[ms:惊讶]': 'ms_015',
  '[ms:难过]': 'ms_016',
  '[ms:囧]': 'ms_017',
  '[ms:抓狂]': 'ms_018',
  '[ms:吐]': 'ms_019',
  '[ms:偷笑]': 'ms_020',
  '[ms:愉快]': 'ms_021',
  '[ms:白眼]': 'ms_022',
  '[ms:傲慢]': 'ms_023',
  '[ms:困]': 'ms_024',
  '[ms:惊恐]': 'ms_025',
  '[ms:憨笑]': 'ms_026',
  '[ms:天使笑]': 'ms_027',
  '[ms:咒骂]': 'ms_028',
  '[ms:疑问]': 'ms_029',
  '[ms:思考]': 'ms_030',
  '[ms:嘘]': 'ms_031',
  '[ms:晕]': 'ms_032',
  '[ms:气愤]': 'ms_033',
  '[ms:衰]': 'ms_034',
  '[ms:恶魔笑]': 'ms_035',
  '[ms:恶魔难过]': 'ms_036',
  '[ms:挨了揍]': 'ms_037',
  '[ms:尬笑]': 'ms_038',
  '[ms:微笑流泪]': 'ms_039',
  '[ms:汗]': 'ms_040',
  '[ms:卖萌]': 'ms_041',
  '[ms:哈欠]': 'ms_042',
  '[ms:无语]': 'ms_043',
  '[ms:伤心]': 'ms_044',
  '[ms:亲亲]': 'ms_045',
  '[ms:惊喜]': 'ms_046',
  '[ms:吓呆]': 'ms_047',
  '[ms:惊吓]': 'ms_048',
  '[ms:恐惧]': 'ms_049',
  '[ms:呆住]': 'ms_050',
  '[ms:飞吻]': 'ms_051',
  '[ms:流口水]': 'ms_052',
  '[ms:崇拜]': 'ms_053',
  '[ms:沉迷金钱]': 'ms_054',
  '[ms:示爱]': 'ms_055',
  '[ms:调皮]': 'ms_056',
  '[ms:二哈笑]': 'ms_057',
  '[ms:笑哭]': 'ms_058',
  '[ms:天啊]': 'ms_059',
  '[ms:热]': 'ms_060',
  '[ms:庆祝]': 'ms_061',
  '[ms:口罩护体]': 'ms_062',
  '[ms:指缝偷看]': 'ms_063',
  '[ms:捂嘴]': 'ms_064',
  '[ms:托腮]': 'ms_065',
  '[ms:严肃脸]': 'ms_066',
  '[ms:盯]': 'ms_067',
  '[ms:嘴角上扬]': 'ms_068',
  '[ms:可怜]': 'ms_069',
  '[ms:呵呵]': 'ms_070',
  '[ms:长叹]': 'ms_071',
  '[ms:吐舌]': 'ms_072',
  '[ms:敬礼]': 'ms_073',
  '[ms:冷眼]': 'ms_074',
  '[ms:偷看]': 'ms_075',
  '[ms:揉脸]': 'ms_076',
  '[ms:学问渊博]': 'ms_077',
  '[ms:眼镜微笑]': 'ms_078',
  '[ms:小丑微笑]': 'ms_079',
  '[ms:牛仔微笑]': 'ms_080',
  '[ms:咧嘴笑]': 'ms_081',
  '[ms:咧嘴眯眼笑]': 'ms_082',
  '[ms:休息]': 'ms_083',
  '[ms:眯眼笑]': 'ms_084',
  '[ms:闭眼]': 'ms_085',
  '[ms:斗鸡眼]': 'ms_086',
  '[ms:担心难过]': 'ms_087',
  '[ms:嫌弃]': 'ms_088',
  '[ms:丢脸]': 'ms_089',
  '[ms:伤心难过]': 'ms_090',
  '[ms:发烧]': 'ms_091',
  '[ms:流鼻涕]': 'ms_092',
  '[ms:脑洞大开]': 'ms_093',
}

export const otherEmojiMap = {
  '[ms:圣诞老人]': 'ms_094',
  '[ms:双眼看]': 'ms_095',
  '[ms:皇冠]': 'ms_096',
  '[ms:蛋糕]': 'ms_097',
  '[ms:欢庆]': 'ms_098',
  '[ms:喝彩]': 'ms_099',
  '[ms:勾引]': 'ms_100',
  '[ms:爱心]': 'ms_101',
  '[ms:心碎]': 'ms_102',
  '[ms:玫瑰]': 'ms_103',
  '[ms:凋谢]': 'ms_104',
  '[ms:柠檬]': 'ms_105',
  '[ms:红包]': 'ms_106',
  '[ms:灯笼]': 'ms_107',
  '[ms:炸弹]': 'ms_108',
  '[ms:便便]': 'ms_109',
  '[ms:问号]': 'ms_110',
  '[ms:感叹号]': 'ms_111',
  '[ms:感叹问号]': 'ms_112',
  '[ms:双感叹号]': 'ms_113',
  '[ms:气炸]': 'ms_114',
  '[ms:满分]': 'ms_115',
  '[ms:zzz]': 'ms_116',
  '[ms:幽灵]': 'ms_117',
  '[ms:恶魔]': 'ms_118',
  '[ms:可]': 'ms_119',
  '[ms:无]': 'ms_120',
  '[ms:有]': 'ms_121',
  '[ms:比较]': 'ms_122',
  '[ms:秘]': 'ms_123',
  '[ms:祝]': 'ms_124',
  '[ms:得]': 'ms_125',
  '[ms:禁]': 'ms_126',
  '[ms:中]': 'ms_127',
  '[ms:版权所有]': 'ms_128',
  '[ms:商标]': 'ms_129',
  '[ms:TM]': 'ms_130',
  '[ms:对]': 'ms_131',
  '[ms:错]': 'ms_132',
  '[ms:葡萄]': 'ms_133',
  '[ms:桃子]': 'ms_134',
  '[ms:菠萝]': 'ms_135',
  '[ms:苹果]': 'ms_136',
  '[ms:草莓]': 'ms_137',
  '[ms:西红柿]': 'ms_138',
  '[ms:西瓜]': 'ms_139',
  '[ms:香蕉]': 'ms_140',
  '[ms:啤酒]': 'ms_141',
  '[ms:香烟]': 'ms_142',
  '[ms:茶]': 'ms_143',
  '[ms:糖果]': 'ms_144',
  '[ms:棒棒糖]': 'ms_145',
  '[ms:冰淇淋]': 'ms_146',
  '[ms:板栗]': 'ms_147',
  '[ms:米饭]': 'ms_148',
  '[ms:饺子]': 'ms_149',
  '[ms:汉堡包]': 'ms_150',
  '[ms:披萨]': 'ms_151',
  '[ms:鸡腿]': 'ms_152',
  '[ms:茄子]': 'ms_153',
  '[ms:大蒜]': 'ms_154',
  '[ms:足迹]': 'ms_155',
  '[ms:脚印]': 'ms_156',
  '[ms:滑板]': 'ms_157',
  '[ms:自行车]': 'ms_158',
  '[ms:电动车]': 'ms_159',
  '[ms:摩托车]': 'ms_160',
  '[ms:三轮车]': 'ms_161',
  '[ms:公交车]': 'ms_162',
  '[ms:轨道电车]': 'ms_163',
  '[ms:皮卡]': 'ms_164',
  '[ms:小轿车]': 'ms_165',
  '[ms:跑车]': 'ms_166',
  '[ms:飞机起飞]': 'ms_167',
  '[ms:飞行中]': 'ms_168',
  '[ms: 飞机降落]': 'ms_169',
  '[ms:火箭]': 'ms_170',
  '[ms:飞船]': 'ms_171',
  '[ms:货车]': 'ms_172',
  '[ms:急救车]': 'ms_173',
  '[ms:消防车]': 'ms_174',
  '[ms:大晴天]': 'ms_175',
  '[ms:多云]': 'ms_176',
  '[ms:阴天]': 'ms_177',
  '[ms:下雨天]': 'ms_178',
  '[ms:晴天]': 'ms_179',
  '[ms:太阳]': 'ms_180',
  '[ms:彩虹]': 'ms_181',
  '[ms:雪花]': 'ms_182',
  '[ms:篮球]': 'ms_183',
  '[ms:排球]': 'ms_184',
  '[ms:台球]': 'ms_185',
  '[ms:足球]': 'ms_186',
  '[ms:羽毛球]': 'ms_187',
  '[ms:网球]': 'ms_188',
  '[ms:乒乓球]': 'ms_189',
  '[ms:橄榄球]': 'ms_190',
  '[ms:游泳]': 'ms_191',
  '[ms:飞镖]': 'ms_192',
  '[ms:金牌]': 'ms_193',
  '[ms:银牌]': 'ms_194',
  '[ms:铜牌]': 'ms_195',
  '[ms:太阳镜]': 'ms_196',
  '[ms:眼镜]': 'ms_197',
  '[ms:奶瓶]': 'ms_198',
  '[ms:电灯泡]': 'ms_199',
  '[ms:闹钟]': 'ms_200',
  '[ms:药丸]': 'ms_201',
  '[ms:蝴蝶结]': 'ms_202',
  '[ms:围巾]': 'ms_203',
  '[ms:蜡烛]': 'ms_204',
  '[ms:火]': 'ms_205',
  '[ms:抽奖]': 'ms_206',
  '[ms:骰子]': 'ms_207',
  '[ms:阅读]': 'ms_208',
  '[ms:学士帽]': 'ms_209',
  '[ms:银行卡]': 'ms_210',
  '[ms:身份卡]': 'ms_211',
  '[ms:马桶搋]': 'ms_212',
  '[ms:马桶]': 'ms_213',
  '[ms:钱包]': 'ms_214',
  '[ms:注射器]': 'ms_215',
  '[ms:戒指]': 'ms_216',
  '[ms:话筒]': 'ms_217',
  '[ms:吉他]': 'ms_218',
  '[ms:照相机]': 'ms_219',
  '[ms:摄影机]': 'ms_220',
  '[ms:拼图]': 'ms_221',
  '[ms:太极]': 'ms_222',
  '[ms:外星人]': 'ms_223',
  '[ms:放大镜]': 'ms_224',
  '[ms:钥匙]': 'ms_225',
  '[ms:口红]': 'ms_226',
  '[ms:花瓶]': 'ms_227',
  '[ms:钻石]': 'ms_228',
  '[ms:浴缸]': 'ms_229',
  '[ms:电脑]': 'ms_230',
  '[ms:加油站]': 'ms_231',
  '[ms:磁铁]': 'ms_232',
  '[ms:循环]': 'ms_233',
  '[ms:喇叭]': 'ms_234',
  '[ms:星星闪闪]': 'ms_235',
  '[ms:蜘蛛网]': 'ms_236',
  '[ms:水果刀]': 'ms_237',
  '[ms:斧头]': 'ms_238',
  '[ms:锚]': 'ms_239',
  '[ms:气球]': 'ms_240',
  '[ms:拐杖]': 'ms_241',
  '[ms:横红绿灯]': 'ms_242',
  '[ms:竖红绿灯]': 'ms_243',
  '[ms:警告牌]': 'ms_244',
  '[ms:回到顶部]': 'ms_245',
  '[ms:免费]': 'ms_246',
  '[ms:顶]': 'ms_247',
  '[ms:人名币]': 'ms_248',
  '[ms:美元]': 'ms_249',
  '[ms:英镑]': 'ms_250',
  '[ms:欧元]': 'ms_251',
  '[ms:落叶]': 'ms_252',
  '[ms:飘零]': 'ms_253',
  '[ms:幸运草]': 'ms_254',
  '[ms:枫叶]': 'ms_255',
  '[ms:羽毛]': 'ms_256',
  '[ms:向日葵]': 'ms_257',
  '[ms:圣诞树]': 'ms_258',
  '[ms:雪人]': 'ms_259',
}

export const handEmojiMap = {
  '[ms:向上]': 'ms_260',
  '[ms:向下]': 'ms_261',
  '[ms:向左]': 'ms_262',
  '[ms:向右]': 'ms_263',
  '[ms:左手]': 'ms_264',
  '[ms:右手]': 'ms_265',
  '[ms:左手掌]': 'ms_266',
  '[ms:右手掌]': 'ms_267',
  '[ms:比心]': 'ms_268',
  '[ms:强]': 'ms_269',
  '[ms:弱]': 'ms_270',
  '[ms:剪刀手]': 'ms_271',
  '[ms:爱你]': 'ms_272',
  '[ms:摇滚]': 'ms_273',
  '[ms:挥手]': 'ms_274',
  '[ms:鼓掌]': 'ms_275',
  '[ms:握手]': 'ms_276',
  '[ms:比爱心]': 'ms_277',
  '[ms:OK]': 'ms_278',
  '[ms:666]': 'ms_279',
  '[ms:竖中指]': 'ms_280',
  '[ms:拳头]': 'ms_281',
  '[ms:指向你]': 'ms_282',
  '[ms:加油]': 'ms_283',
  '[ms:左击拳]': 'ms_284',
  '[ms:右击拳]': 'ms_285',
  '[ms:拿]': 'ms_286',
  '[ms:接]': 'ms_287',
  '[ms:捏]': 'ms_288',
  '[ms:交叉手指]': 'ms_289',
  '[ms:掐]': 'ms_290',
  '[ms:握拳]': 'ms_291',
  '[ms:举手]': 'ms_292',
  '[ms:点击]': 'ms_293',
  '[ms:手掌]': 'ms_294',
  '[ms:摊手]': 'ms_295',
  '[ms:双手捧]': 'ms_296',
  '[ms:拍拍双手]': 'ms_297',
}

export const animalEmojiMap = {
  '[ms:小鸡仔]': 'ms_298',
  '[ms:鸡]': 'ms_299',
  '[ms:公鸡]': 'ms_300',
  '[ms:蚂蚁]': 'ms_301',
  '[ms:蝙蝠]': 'ms_302',
  '[ms:熊]': 'ms_303',
  '[ms:河狸]': 'ms_304',
  '[ms:七星瓢虫]': 'ms_305',
  '[ms:小鸟]': 'ms_306',
  '[ms:奶牛]': 'ms_307',
  '[ms:野牛]': 'ms_308',
  '[ms:牛头]': 'ms_309',
  '[ms:虫]': 'ms_310',
  '[ms:蝴蝶]': 'ms_311',
  '[ms:猫咪]': 'ms_312',
  '[ms:猫头]': 'ms_313',
  '[ms:松鼠]': 'ms_314',
  '[ms:螃蟹]': 'ms_315',
  '[ms:蚱蜢]': 'ms_316',
  '[ms:鳄鱼]': 'ms_317',
  '[ms:鹿]': 'ms_318',
  '[ms:鸭]': 'ms_319',
  '[ms:狗]': 'ms_320',
  '[ms:狗头]': 'ms_321',
  '[ms:格子]': 'ms_322',
  '[ms:龙]': 'ms_323',
  '[ms:龙头]': 'ms_324',
  '[ms:鹅]': 'ms_325',
  '[ms:鹰]': 'ms_326',
  '[ms:大象]': 'ms_327',
  '[ms:苍蝇]': 'ms_328',
  '[ms:狐狸]': 'ms_329',
  '[ms:青蛙]': 'ms_330',
  '[ms:长颈鹿]': 'ms_331',
  '[ms:山羊]': 'ms_332',
  '[ms:绵羊]': 'ms_333',
  '[ms:导盲犬]': 'ms_334',
  '[ms:刺猬]': 'ms_335',
  '[ms:蜜蜂]': 'ms_336',
  '[ms:马]': 'ms_337',
  '[ms:马头]': 'ms_338',
  '[ms:袋鼠]': 'ms_339',
  '[ms:狮子]': 'ms_340',
  '[ms:壁虎]': 'ms_341',
  '[ms:龙虾]': 'ms_342',
  '[ms:猴子]': 'ms_343',
  '[ms:猴头]': 'ms_344',
  '[ms:蚊子]': 'ms_345',
  '[ms:老鼠]': 'ms_346',
  '[ms:仓鼠]': 'ms_347',
  '[ms:老鼠头]': 'ms_348',
  '[ms:章鱼]': 'ms_349',
  '[ms:熊猫]': 'ms_350',
  '[ms:鹦鹉]': 'ms_351',
  '[ms:孔雀]': 'ms_352',
  '[ms:猪]': 'ms_353',
  '[ms:兔子]': 'ms_354',
  '[ms:海豹]': 'ms_355',
  '[ms:鲨鱼]': 'ms_356',
  '[ms:黄鼠狼]': 'ms_357',
  '[ms:蜗牛]': 'ms_358',
  '[ms:蛇]': 'ms_359',
  '[ms:蜘蛛]': 'ms_360',
  '[ms:海螺]': 'ms_361',
  '[ms:海豚]': 'ms_362',
  '[ms:老虎]': 'ms_363',
  '[ms:火鸡]': 'ms_364',
  '[ms:乌龟]': 'ms_365',
  '[ms:骆驼]': 'ms_366',
  '[ms:恐龙]': 'ms_367',
  '[ms:斑马]': 'ms_368',
}

export function string2emoji(words) {
  const keys = words.match(/\[(.+?)\]/g) || []
  let result = words
  for (const key of keys) {
    if (faceEmojiMap[key]) {
      result = result.replace(new RegExp(`\\[${key.slice(1, -1)}\\]`, 'g'), `<img src="${this.$cdn}/ms/${faceEmojiMap[key]}.png" alt="${faceEmojiMap[key]}" class="emoji">`)
    }
    if (otherEmojiMap[key]) {
      result = result.replace(new RegExp(`\\[${key.slice(1, -1)}\\]`, 'g'), `<img src="${this.$cdn}/ms/${otherEmojiMap[key]}.png" alt="${otherEmojiMap[key]}" class="emoji">`)
    }
    if (handEmojiMap[key]) {
      result = result.replace(new RegExp(`\\[${key.slice(1, -1)}\\]`, 'g'), `<img src="${this.$cdn}/ms/${handEmojiMap[key]}.png" alt="${handEmojiMap[key]}" class="emoji">`)
    }
    if (animalEmojiMap[key]) {
      result = result.replace(new RegExp(`\\[${key.slice(1, -1)}\\]`, 'g'), `<img src="${this.$cdn}/ms/${animalEmojiMap[key]}.png" alt="${animalEmojiMap[key]}" class="emoji">`)
    }
  }
  return result
}

export function deleteEmoji(text) {
  const reg = /\[[^\]]+?\]$/
  if (reg.test(text)) {
    return text.replace(reg, '')
  } else if (text) {
    return text.slice(0, -1)
  }
}